<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="about" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container">
                    <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p>
                </div>
            </section>
            <section class="main-content film">
                <div class="container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/bsX8UQj2EMw"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                        <div class="loghi">
                            <img src="../assets/sida.svg" alt="">
                            <img src="../assets/richard-lounsbery-foundation.jpg" alt="">
                            <img src="../assets/hyphae.jpg" alt="">
                            <img src="../assets/fondo-audiovisivo-fvg.svg" alt="">
                        </div>
                    <p v-html="this.pageData.attributes.field_description_w_summary.value">
                    </p>

                    <div><h3 class="section-title">Sections Index</h3></div>
                    <p><ul>
                        <li v-for="(u, index) in this.pageData.field_ref_ui_text" :key="index">
                            <a @click="gotohash('film'+(index+1))" class="section-link">{{ u.attributes.title }}</a>
                        </li>
                    </ul></p>

                    <div v-for="(u, index) in this.pageData.field_ref_ui_text" :key="index" :id="'film'+(index+1)">
                        <h4 class="section-title">{{u.attributes.title}}</h4>
                        <div v-html="u.attributes.field_description_w_summary.value"></div>
                    </div>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'

    import {
        fetchSinglePage
    } from '../libs/drupalClient'
    import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    export default {
        name: 'science-exile-film',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "102ca3a8-b208-4913-8ed5-acd99589d110"
            }
        },
        computed: {},
        methods: {
            gotohash(n) {
                //console.log(n, document.getElementById(n).getBoundingClientRect().top, document.getElementById(n).getBoundingClientRect().y, window.scrollY)
                if(document.getElementById(n))
                    window.scrollBy({
                        top: document.getElementById(n).getBoundingClientRect().top - 180,
                        behavior: 'smooth'
                    })
            }
        },
        async mounted() {
            await fetchSinglePage(this.currentPageID, {
                    include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text','field_attachments']
                })
                .then(res => {
                    this.pageData = res
                })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .section-link {
        color: $darkblue;
        text-decoration: underline;
        &:hover {
            color: $orange;
            cursor: pointer;
        }
    }

    .film {
        .section-title {
            color: $green;
            max-width: 1030px;
            margin: 3rem auto 0;
        }
    }

    iframe {
        margin-bottom: 2rem;
        width: 100%;
    }

    .loghi {
        img {
            width: 100%;
            object-fit: contain;
            max-height: 80px;
            margin-bottom: 2rem;
        }
    }

    @media (min-width:1024px) {
        iframe {
            height: 540px;
        }
        .loghi {
            display: flex;
            margin: 3rem 0rem;
            img {
                width: 100%;
                object-fit: contain;
                max-height: 80px;
                margin-bottom: 2rem;
            }
        }
    }

    @media (min-width:1200px) {
        
        iframe {
            max-width: 1030px;
            margin: 3rem auto 2rem auto;
            display: flex;
            width: 100%;
            height: 578px;
        }
    }


    @media (min-width:1024px) {
        .film {
            .section-title {
                margin: 4rem auto 0;
            }
        }
    }
</style>